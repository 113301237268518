// MEMO: import this for IE11.
// MEMO: Swiper 5.4.5 works on IE11.
// import Swiper from "../../node_modules/swiper/js/swiper";

// import the latest version
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

/*
 *
 * ----------------------------------------------- */
var swiperSimple = document.querySelector('.swiper-fv')

if (swiperSimple) {
  new Swiper(swiperSimple, {
    effect: 'fade',
    loop: true,
    speed: 600,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })
}
