import { isMobile } from './is-mobile'

/*
 * Fixed position header
 * ----------------------------------------------- */
// jQuery(window).on('load scroll', function () {
//   if (isMobile()) {
//     return false
//   }
//
//   var $jsHeader = $('.js-header')
//
//   $jsHeader.each(function () {
//     var scroll = $(window).scrollTop()
//
//     if (scroll > 500) {
//       $jsHeader.addClass('is-scrolled')
//     } else {
//       $jsHeader.removeClass('is-scrolled')
//     }
//   })
// })

jQuery(function ($) {
  var $menuTrigger = $('.menu-trigger')

  $('.navbar-nav .nav-link, .navbar-nav .btn-contact').on('click', function (event) {
    if (isMobile() && !$(this).hasClass('btn-collapse')) {
      $menuTrigger[0].click()
    }
  })
})
